import request from '@/utils/request'

//实习请假审核
export function verifypracticeleave(data) {
    return request({
        url: '/portal/practice-leave/verify',
        method: 'post',
        data
    })
}
//实习请假申请
export function addpracticeleave(data) {
    return request({
        url: '/portal/practice-leave/add',
        method: 'post',
        data
    })
}

//实习请假详情
export function getpracticeleaveinfo(params) {
    return request({
        url: '/portal/practice-leave/get-info',
        method: 'GET',
        params
    })
}

//获取实习请假列表
export function getpracticeleavelist(params) {
    return request({
        url: '/portal/practice-leave/get-list',
        method: 'GET',
        params
    })
}